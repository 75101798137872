import axios from 'axios';
export default {
    data: () => ({
      userDataDefault: {
        id: '',
        name: '',
        email: '',
      },
      menuItemListDefault: [{
        action: "mdi-menu",
        items: [
          { title: 'Op 1', icon: 'mdi-folder', link	:	'Empty1' },
          { title: 'Op 2', icon: 'mdi-folder', link	:	'Empty2' },      ], 
        title: 'Menu', 
      }],
      processing: 0,
      daysNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],  
    }),
    methods: {
      getFocus: async function (val) {
        await new Promise(resolve => setTimeout(resolve, 500)) // Pausa
        await this.$refs[val].focus();
      },
      setRoutes: async function (comp,require,nameID,path) {
        await this.$router.addRoute({
          component: () => import( '../views/' + comp),
          meta: { requiresAuth: require},
          name: nameID,
          path: path
        })
      },
      getRouteNames: async function () {
        let routes = await [];
        let routesName = await [];
        routes = await this.$router.getRoutes();
        await routes.forEach(route => {
          routesName.push({
            name: route.name,
            // path: route.path
          })
        });
        this.listRoutes = await JSON.stringify(routesName);
      },
      createLog: async function (idUser, event){
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        let apiRoute = await 'logcreate';
        let formData = await {
          user_id : idUser,
          event : event
        };
        const returnData = await false;
        // *** let logCreate = await this.callAPI(formData,apiRoute,token,returnData);
        await this.callAPI(formData,apiRoute,token,returnData);
      },
      //
      setToken (val){
        this.$store.dispatch('loadToken', val);
        if(val === ''){
          sessionStorage.removeItem('localToken');
          this.$store.dispatch('loadLoggedIn', 0);
        } else {
          sessionStorage.setItem('localToken', JSON.stringify(val));
        }
      },
      async callAPI (data,route,token,returnData) {
        await this.setOverlay();
        let dataResponse = await '';
        let dataStatus = await null;
        // const token = await JSON.parse(sessionStorage.getItem('localToken'));
        await axios({
          method: 'post', url: this.urlApi + '/' + route,
          responseType: 'json',
          data: data,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }}).then(response => dataResponse = response.data).catch(e => {
            dataResponse = e.response.data;
          });
        await this.setOverlay();
        if (await dataResponse.status){
          await console.log(dataResponse.status);
          dataStatus = await dataResponse.status;
        }
        if (await dataStatus === 'Token is Expired' || await dataStatus === 'Token is Invalid')  {
          await this.$store.dispatch('loadMessage', {
            msg: 'Aviso: La sesion expiro. vuelva a ingresar', type: 'error'
          });
          // *** await this.createLog(8, 'Expired');
          await this.$store.dispatch('loadUserData', this.userDataDefault);
          await this.$store.dispatch('loadMenuItemList', this.menuItemListDefault);
          await this.$store.dispatch('loadMyRoutes', []);
          // *** this.responseData = await [];
          await this.loadRoute('Home', {});
        }
        if (await returnData) {
          // await console.log(dataResponse);
          return await dataResponse;
        }
      },
      //
      async getTokenInfo () {
        await this.$store.dispatch('loadLoggedIn', 0);
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'user';
          let formData = await {};
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          if (await dataResponse) {  
            if (await dataResponse.name){
              dataResponse['FL'] = await this.getFirstLetters(dataResponse.name);
            }
            if(await dataResponse.active === '0'){
              await this.$store.dispatch('loadMessage', {
                msg: 'Aviso: Acceso no permitido, usuario deshabilitado', type: 'error'
              });
              await this.closeApp(dataResponse.id, 'UserOff');
            } else {
              //
              await this.$store.dispatch('loadUserData', dataResponse);
              await this.getMenuItemList();
            }
          }
        }
        
      },
      async getMenuItemList () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          await this.$store.dispatch('loadLoggedIn', 1);
          let apiRoute = await 'profile/' + this.userData.id; // 'menugetactive'
          let formData = await {};
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          let myRoutes = await [];
          await myRoutes.push('Home','Login','Logged','NotFound','Miscellan');
          await this.$store.dispatch('loadMyRoutes', myRoutes);
          if (await dataResponse.data.length !== 0){
            await this.$store.dispatch('loadMenuItemList', dataResponse.data);
            await dataResponse.data.forEach(function(entry){
              entry.items.forEach(function(childrenEntry){
                myRoutes.push(childrenEntry.link);
                // console.log(childrenEntry.link);
              });
            });
            await this.$store.dispatch('loadMyRoutes', myRoutes);
          }
          if (await this.routeName !== 'Logged' && await this.routeName === 'Login') {
            await this.$router.push({name: 'Logged', params: {}});
          }
        } else {
          //
        }
      },
      //
      async closeApp (idUser, param) {
        if(await param === 'Logout'){
          //
        }
        await this.createLog(idUser, param);
        await this.$store.dispatch('loadUserData', this.userDataDefault);
        await this.$store.dispatch('loadMenuItemList', this.menuItemListDefault);
        await this.$store.dispatch('loadMyRoutes', []);
        await this.loadRoute('Home', {});
      },
      //
      async loadRoute (route, param) {
        if(await this.$route.name !== route){
          await this.$router.push({ name: route, params: param });
        }
      },
      //
      async createLogUserOLD (email, event) {
        let apiRoute = await 'loguser';
        let formData = await {'email': email, 'event': event};
        let Token = await this.token;
        await this.calldata(apiRoute, formData, Token);
      },
      async displayMsg (msgtxt, msgtype){
        this.$store.dispatch('loadMessage', {msg: msgtxt, type: msgtype});
      },
      async setOverlay (){
        await this.$store.dispatch('loadOverlay');
      },
      toggleTheme() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        sessionStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      },
      getFirstLetters: function (val) {
        if (val.length !== 0) {
          return val.split(' ').map(word => word.charAt(0)).join('');
        } else {
          return '';
        }
      },
      getDateStr: function (val) {
        const dateCal = new Date(val);
        const dateCalStr = dateCal.getFullYear() + '-' +
            (this.leadZero((dateCal.getMonth() + 1),2)) + '-' + this.leadZero(dateCal.getDate(),2) + ' ' + 
            this.leadZero(dateCal.getHours(),2) + ':' + this.leadZero(dateCal.getMinutes(),2) + ':' +
            this.leadZero(dateCal.getSeconds(),2);
        //
        return dateCalStr;
      },
      getFullDate (val) {
        const dt = new Date(val);
        var hours = dt.getHours()
        var minutes = dt.getMinutes()
        var ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12
        hours = hours ? hours : 12
        minutes = minutes < 10 ? '0'+minutes : minutes
        var strTime = hours + ':' + minutes + ' ' + ampm
        return this.daysNames[dt.getDay()] + ' ' +  dt.getDate() + ' ' + this.monthNames[dt.getMonth()] + ' ' + dt.getFullYear() + ' ' + strTime
      },
      getFullDate2 (val) {
        const dt = new Date(val);
        // var hours = dt.getHours()
        // var minutes = dt.getMinutes()
        // var ampm = hours >= 12 ? 'pm' : 'am'
        // hours = hours % 12
        // hours = hours ? hours : 12
        // minutes = minutes < 10 ? '0'+minutes : minutes
        // var strTime = hours + ':' + minutes + ' ' + ampm
        return this.daysNames[dt.getDay()] + ' ' +  dt.getDate() + ' ' + this.monthNames[dt.getMonth()] + ' ' + dt.getFullYear();
      },
      getToday: function () {
        let today = null
        const tz = new Date()
        today = tz.getFullYear() + '-' +
          this.leadZero((tz.getMonth() + 1),2) + '-' + 
          this.leadZero(tz.getDate(),2)
        return today
      },
      leadZero: function (val, size) {
        let s = val+''
        while (s.length < size) s = "0" + s;
        return s;
      },
      makeString (length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      async scrollToTop() {
        await window.scrollTo(0,0);
        document.body.scrollTop = await  0;
        document.documentElement.scrollTop = await  0;
      },
      isEmpty: function (dat, message, textfield) { // dat, message, textfield
        // alert(typeof dat)
        let error = 0;

        if((typeof dat)==='number'){
          if(dat===0){
            error = 1;
          // } else {
          //   error = 0;
          }
        }

        if((typeof dat)==='undefined'){
          error = 1;
        }

        if((typeof dat)==='object'){
          error = 1;
        }

        if((typeof dat)==='string'){
          dat.replace(/^\s+|\s+$/gm,'')
          if(dat.length===0){
            error = 1;
          } else {
            error = 0;
          }
        } else {
          // error = 1;
        }
        //
        if(error===0){
          return true;
        } else {
          this.$store.dispatch('loadMessage', { msg: message, type: 'error' });
          if(textfield.length !== 0){
            const ElementById = document.getElementById(textfield);
            // alert(JSON.stringify(ElementById));
            if(ElementById !== null){
              document.getElementById(textfield).focus();
            } else {
              // rev this.$refs.text_movil.focus();
              this.$refs[textfield].focus();
            }
          }
          return false;
        }
      },
      editFocus: function (val){
        this.isEditing = true
        setTimeout(() => {
          this.$refs[val].focus()
        }, 500)
      },
      copyObjects: function (val) {
        return JSON.parse(JSON.stringify(val));
      },
      //
      searchevents: function(){
        let result = this.events
        if (!this.filterValue)
          return result
        const filterValue = this.filterValue.toLowerCase()
        const filter = event =>
            event.title.toLowerCase().includes(filterValue) ||
            event.subtitle.toLowerCase().includes(filterValue) // ||
            // event.tags.some(tag => tag.toLowerCase().includes(filterValue));
        return result.filter(filter)
        // this.filterss = result.filter(filter)
      },
      searchevents2: function(){
        const searchRegex = new RegExp(this.filterValue,'i')
        return this.events.filter(event => 
          !this.filterValue || searchRegex.test(event.name) || searchRegex.test(event.state))
      },
      myTrim: function (val){
        if (val != '' && val != null) {
          return val.replace(/^\s+|\s+$/gm,'');
        }
        return '';
      }
      //
    },
    created () {
      //
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      json_snackbar () {
        return this.$store.getters.getJson_Snackbar
      },
      timeOverlay () {
        return this.$store.getters.getOverlay
      },
      loggedIn () {
        return this.$store.getters.getLoggedIn
      },
      title () {
        return this.$store.getters.getTitle
      },
      urlApi: function () {
        return this.$store.getters.getUrlApi
      },
      token () {
        return this.$store.getters.getToken
      },
      userData () {
        return this.$store.getters.getUserData
      },
      menuItemList () {
        return this.$store.getters.getMenuItemList
      },
      myRoutes () {
        return this.$store.getters.getMyRoutes
      },
      routeName: function () {
        return this.$route.name;
      },
      keyUniqueCodes () {
        return this.$store.getters.getKeyUniqueCodes
      },
      screenSize () {
        return this.$vuetify.breakpoint.name;
      },
    }
}
