<template>
  <v-app>
    <div>
      <v-btn icon @click="toggle_dark_mode" v-show="loggedIn === 0">
          <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
      <v-app-bar
        v-show="loggedIn === 1"
        color="primary"
        dense dark>
        <span class="ma-0 pa-0">{{nameUser}}</span>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" class="ma-0 pa-1"
          @click.stop="dialMenu = !dialMenu"
          >
          <v-icon class="ma-0 pa-1" v-if="dialMenu === false">mdi-menu</v-icon>
          <v-icon left v-else>mdi-menu-down-outline</v-icon>
          <span class="ma-0 pa-0" v-show="screenSize != 'xs'">MENU</span>
        </v-btn>
        <v-btn depressed color="primary" class="ma-0 pa-1"
          @click.stop="dialLogout = !dialLogout">
          <v-icon class="ma-0 pa-1">mdi-exit-to-app</v-icon>
          <span class="ma-0 pa-0" v-show="screenSize != 'xs'">SALIR</span>
        </v-btn>
      </v-app-bar>
    </div>

    <div class="text-center" v-show="loggedIn === 1 && title !== ''">
      <v-alert
        color="primary" dense colored-border class="ma-0">
        <span class="body-1">{{ title }}</span>
      </v-alert>
    </div>

    <v-overlay :value="timeOverlay">
      <v-progress-circular indeterminate size="100"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      v-model="json_snackbar.snackbar"
      :top="true"
      :timeout="json_snackbar.timeout"
      :color="json_snackbar.color"
      >
      {{ json_snackbar.text }}
    </v-snackbar>

    <v-dialog
      v-model="dialMenu"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      >
      <v-card>
        <v-toolbar dark dense color="primary">
          <v-btn icon dark
            @click="dialMenu = false">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title>Menu</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark dense text
              v-show="screenSize != 'xs'"
              @click="dialMenu = false"
              >
              cerrar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line>
          <v-subheader>{{ getFullDate(new Date()) }}</v-subheader>
        </v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img
              :src="require('@/assets/avatar.png')"
              class="my-3" contain height="100%">
            </v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="userData.name"></v-list-item-title>
            <v-list-item-subtitle v-html="userData.personalemail"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense>
          <!-- v-list-item link :to="{ name: 'Logged', params: {}}">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Principal</v-list-item-title>
          </v-list-item -->
          <v-list-group
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
            >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              @click.stop="clickItemMenu(child)">
              <v-list-item-icon>
                <v-icon v-text="child.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="child.title">XX</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
    </v-dialog>


    <!-- v-dialog
      transition="dialog-top-transition"
      max-width="600" v-model="dialLogout"
      >
      <v-card>
        <v-toolbar dark color="primary">
          Confirmar Salida del Sistema
        </v-toolbar>
        <v-card-text>
          <div v-bind:class="classCardTitle">En realidad desea salir del sistema?</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="red" text @click.stop="dialLogout=false">Cancelar</v-btn>
          <v-btn color="primary" text @click.stop="closeApp(userData.id, 'Logout');">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog -->
    <v-dialog
      transition="dialog-top-transition"
      max-width="600" v-model="dialLogout"
      >
        <v-card>
          <v-toolbar
            color="primary"
            dark dense
          >Confirmar</v-toolbar>
          <v-card-text>
            <div v-bind:class="classCardTitle">En realidad desea salir del sistema?</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="red" text @click.stop="dialLogout=false">Cancelar</v-btn>
            <v-btn color="primary" text @click.stop="closeApp(userData.id, 'Logout');">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-main>
      <router-view class="ma-0 pa-0"/>
    </v-main>
    <DIV v-show="urlApi.includes('localhost')">
      <v-btn
        x-small
        color="secondary"
        :to="{ name: 'Empty', params: {}}"
        >
        Test Form
      </v-btn>
      DIALMENU*{{ dialMenu }}* 
      <BR/>
       ROUTERNAME {{routeName}} /
      PARAMS {{$route.params}} <BR/>
      LOGGEDIN {{loggedIn}} / BREAKPOINT {{screenSize}} <BR/>
      MYROUTES {{ myRoutes}} <BR/>
      USERDATA {{userData}} <BR/>
      MENUITEMLIST {{ menuItemList }}<BR/>
      <v-text-field label="Token" v-model="token" disabled></v-text-field><BR/>
      {{ urlApi }} <BR/>
      {{ 'routesNames' }} <BR/>
      {{ listRoutes }} <BR/>
      {{ '$router.getRoutes()' }}
    </DIV>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    props: {
      //
    },
    components: {
      //
    },
    data: () => ({
      //
      listRoutes: [],
      toDay: null,
      dialMenu: false,
      dialLogout: false,
      items: [],
      //
      // title: '',
    }),
    methods: {
      initialize: function () {
        //
      },
      processData: function () {
        //
      },
      clickItemMenu: async function (val) {
        // *** this.$store.dispatch('loadLabelBar', '');
        // alert(JSON.stringify(val));
        // *** await console.log(val);
        if (await this.routeName !== val.link){
          await this.$store.dispatch('loadTitle', val.title);
          await this.$router.push({name: val.link, params: {}});
        }
        if(await this.dialMenu) { this.dialMenu = await false; }
      },
      getback: async function () {
        await this.getTokenInfo();
      },
      watchrouteName: async function (val){
        if (await val === 'Logged'){
          await this.$store.dispatch('loadTitle', '');
        }
        if(await this.dialLogout) { this.dialLogout = await false }
        await sessionStorage.setItem('localRouteName', String(val));
        
        if(await this.loggedIn === 1){
          const nextRoute = await this.myRoutes.find((element) => element === val);
          if (await nextRoute) {
            if (await this.routeName !== 'Logged')  {
              const token = await JSON.parse(sessionStorage.getItem('localToken'));
              let apiRoute = await 'logcreate';
              let formData = await {
                user_id : this.userData.id,
                event : this.routeName
              };
              if (await this.userData.id) {
                const returnData = await false;
                // *** let logCreate = await this.callAPI(formData,apiRoute,token,returnData);
                await this.callAPI(formData,apiRoute,token,returnData);
              }
            }
          } else {
            await this.$router.push({name: 'Logged', params: {}});
            let msg = await 'Aviso. Esta opcion no se encuentra disponible para su usuario';
            let msgtype = await 'error';
            await this.displayMsg(msg, msgtype);
          }
        }
      },
      watchdialMenu: async function (val){
        if (await val) {
          if (await this.menuItemList[0].action !== 'mdi-menu') {
            this.items = await this.menuItemList;
          } else {
            this.items = await [];
            let msg = await 'Aviso. Este usuario no tiene opciones asignadas del sistema';
            let msgtype = await 'error';
            await this.displayMsg(msg, msgtype);
            await setTimeout(() => {
              this.dialMenu = false;
            }, 5000);

          }
        }
      },
      addRoutes: async function () {
        await this.setRoutes('EmptyView.vue',false,'Empty','/empty');
        await this.setRoutes('EmptyView.vue',false,'Empty1','/empty');
        await this.setRoutes('EmptyView.vue',false,'Empty2','/empty');
        await this.setRoutes('EmptyView.vue',false,'Empty3','/empty');
        await this.setRoutes('Login.vue',false,'Login','/login');
        await this.setRoutes('Logged.vue',true,'Logged','/logged');
        await this.setRoutes('User.vue',true,'User','/user');
        await this.setRoutes('Appoint.vue',true,'Appoint','/appoint');
        await this.setRoutes('Profile.vue',true,'Profile','/profile');
        await this.setRoutes('UserData.vue',true,'UserData','/userdata');
        await this.setRoutes('CatalogPre.vue',true,'CatalogPre','/catalog');
        await this.setRoutes('AppLs.vue',true,'AppLs','/aplist');
        await this.setRoutes('Testing.vue',true,'Testing','/testing');
        await this.setRoutes('Reason.vue',true,'Reason','/reason');
        await this.setRoutes('Doctor.vue',true,'Doctor','/doctor');
        await this.setRoutes('NotFound.vue',false,'PageNotFound','/:pathMatch(.*)*');
        await this.getRouteNames();
      },
      toggle_dark_mode: function() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        sessionStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
        if ( this.routeName === 'Login'){
        //   this.getFocus('usuario');
          document.getElementById('usuario').focus();
        }
      }
    },
    beforeCreate () {
      //
    },
    created () {
      this.addRoutes();
      this.$store.dispatch('loadTitle', JSON.parse(sessionStorage.getItem('localTitle')));
      this.dialogPass=true;
    },
    mounted () {
      const theme = sessionStorage.getItem("dark_theme");
      if (theme) {
        if (theme === "true") {
          this.$vuetify.theme.dark = true;
        } else {
          this.$vuetify.theme.dark = false;
        }
      } else if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          this.$vuetify.theme.dark = true;
          sessionStorage.setItem(
            "dark_theme",
            this.$vuetify.theme.dark.toString()
        );
      }
      //
      if (this.$route.name === 'Hone'){
        
        this.$store.dispatch('loadUserData', this.userDataDefault);
        this.$store.dispatch('loadToken', '');
        this.$store.dispatch('loadMyRoutes', []);
        this.$store.dispatch('loadMenuItemList', []);
        this.$store.dispatch('loadTitle', '');
        
      }
      if (sessionStorage.getItem('localToken')) {
        this.getback();
      }
      this.toDay = this.getToday();
    },
    updated () {
      //
    },
    watch: {
      routeName (val) {
        this.watchrouteName(val);
      },
      dialMenu (val) {
        this.watchdialMenu(val);
      }
    },
    computed: {
      nameUser () {
        if (this.userData.name) {
          let subs = this.userData.name.split(' ');
          return subs[0];
        }
        return '';
      },
      //
      classCardTitle: function () {
        return {
          // 'blue--text': this.selected.items.length !== 0 ? true : false,
          // 'red--text': this.selected.items.length === 0 ? true : false,
          // 'headline': this.imageHeight === 'sm' ? true : false,
          // 'caption': this.imageHeight !== 'sm' ? true : false,
          'text-h4': this.screenSize !== 'xs' ? true : false,
          'text-h6': this.screenSize === 'xs' ? true : false,
          'pa-12' : true,
          // 'light-blue darken-4': true,
          // 'white--text': true,
          //    headline
        }
      }
      //
    }
  }
</script>
