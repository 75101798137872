import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: 0,
    overlay: false,
    token: '',
    title: '',
    myRoutes: [],
    userData: {},
    keyUniqueCodes: [],
    menuItemList: [{
      action: "mdi-menu",
      items: [
        { title: 'Op 1', icon: 'mdi-folder', link	:	'Empty1' },
        { title: 'Op 2', icon: 'mdi-folder', link	:	'Empty2' },      ], 
      title: 'Menu', 
    }],
    json_Snackbar: {
      snackbar: false,
      y: 'top',
      color: 'green',
      mode: '',
      timeout: 4000,
      text: '',
      autoheight: true
    },
    //
    protocol: location.protocol + '//', 
    hostName: location.hostname, 
    appName: 'bious',
    apiName: 'biousapi',
    apiRoute: '/api',
    portNumber: ':8000',
  },
  mutations: {
    setLoggedIn (state, payload) {
      state.loggedIn = payload
    },
    setTitle (state, payload) {
      state.title = payload;
      sessionStorage.setItem('localTitle', JSON.stringify(''));
      if (payload !== ''){
        sessionStorage.setItem('localTitle', JSON.stringify(payload));
      }
    },
    setOverlay (state,) {
      state.overlay = !state.overlay
    },
    setToken (state, payload) {
      state.token = payload
    },
    setUserData (state, payload) {
      state.userData = payload
    },
    setMenuItemList (state, payload) {
      state.menuItemList = payload
    },
    setMyRoutes (state, payload) {
      state.myRoutes = payload
    },
    setKeyUniqueCodes (state, payload) {
      state.keyUniqueCodes = payload
    },
    setMessage (state, payload) {
      state.json_Snackbar.snackbar = true
      state.json_Snackbar.text = payload.msg
      state.json_Snackbar.color = payload.type
    },
  },
  actions: {
    loadLoggedIn ({commit}, payload) {
      commit('setLoggedIn', payload)
    },
    loadTitle ({commit}, payload) {
      commit('setTitle', payload)
    },
    loadOverlay ({commit}, payload) {
      commit('setOverlay', payload)
    },
    loadToken ({commit}, payload) {
      commit('setToken', payload)
    },
    loadUserData ({commit}, payload) {
      commit('setUserData', payload)
    },
    loadMenuItemList ({commit}, payload) {
      commit('setMenuItemList', payload)
    },
    loadMyRoutes ({commit}, payload) {
      commit('setMyRoutes', payload)
    },
    loadKeyUniqueCodes ({commit}, payload) {
      commit('setKeyUniqueCodes', payload)
    },
    loadMessage ({commit}, payload) {
      commit('setMessage', payload)
    },
  },
  getters: {
    getLoggedIn (state) {
      return state.loggedIn
    },
    getTitle (state) {
      return state.title
    },
    getOverlay (state) {
      return state.overlay
    },
    getToken (state) {
      return state.token
    },
    getJson_Snackbar (state) {
      return state.json_Snackbar
    },
    getUserData (state) {
      return state.userData
    },
    getMenuItemList (state) {
      return state.menuItemList
    },
    getMyRoutes (state) {
      return state.myRoutes
    },
    getKeyUniqueCodes (state) {
      return state.keyUniqueCodes
    },
    getUrlApi (state) {
      if (state.hostName === 'localhost'){
        return (state.protocol + state.hostName + 
                state.portNumber + state.apiRoute)
      } else {
        return (state.protocol + 
          state.hostName.replace(state.appName, state.apiName) + 
          state.apiRoute)
      }
    },
  },
  modules: {
  },
})
